<template>
  <div class="mobile-detail">
    <div class="mobile-detail-container" :style="buttons && buttons.length > 0 ? '' : 'margin-bottom:0'">
      <cube-scroll v-if="detail">
        <inputItem title="申请人员" v-model="detail.user_name" :disabled="true"></inputItem>
        <inputItem title="请假类型" type="select" :options="typeOption" v-model="detail.type" :disabled="disabled"></inputItem>
        <inputItem title="开始时间" type="datetime" :initialContent="formatDate(detail.stime, 'yyyy-MM-dd hh:mm')" name="stime" :contentChanged="contentChanged" :disabled="disabled"></inputItem>
        <inputItem title="结束时间" type="datetime" :initialContent="formatDate(detail.etime, 'yyyy-MM-dd hh:mm')" name="etime" :contentChanged="contentChanged" :disabled="disabled"></inputItem>
        <inputItem title="请假事由" v-model="detail.remark" :disabled="disabled"></inputItem>
        <div class="mobile-detail-step" v-if="detail && detail.state > 1">
          <Steps :current="flows.step" :status="flows.status" direction="vertical">
            <Step v-for="(item, index) in flows.flowLogs" :key="index" :title="item.userName" :content="formatDate(item.time, 'yyyy-MM-dd hh:mm') + (item.opinion ? ' - ' + item.opinion : '')"></Step>
          </Steps>
        </div>
      </cube-scroll>
    </div>
    <buttonGroup v-if="buttons && buttons.length > 0" :buttons="buttons" @button-click="buttonClick"></buttonGroup>
  </div>
</template>

<script>
import { GetVacation, SaveVacation, AuditVacation, CancelVacation } from "@/api";
import { formatDate, formatMoney } from "@/utils/format.js";
import { loadDetailAction, submitAction, auditAction, cancelAction, loadDataOptionAction } from "@/utils/action.js";

export default {
  data: function () {
    return {
      id: "",
      detail: null,
      buttons: [],
      flows: [],
      disabled: false,
      typeOption: [],
    };
  },
  methods: {
    formatDate: formatDate,
    formatMoney: formatMoney,
    contentChanged: function (index, value) {
      switch (index) {
        case "stime":
          this.detail.stime = value;
          break;
        case "etime":
          this.detail.etime = value;
          break;
      }
    },
    buttonClick: function (title) {
      switch (title) {
        case "保存草稿":
          this.saveVacation(1);
          break;
        case "提交审核":
          this.saveVacation(2);
          break;
        case "审核通过":
          this.auditVacation(true, "同意");
          break;
        case "审核拒绝":
          this.auditVacation(false, "");
          break;
        case "撤回":
          this.cancelVacation();
          break;
      }
    },
    saveVacation: function (state) {
      this.detail.state = state;
      submitAction(this, SaveVacation, { model: this.detail }, () => { this.$router.go(-1); }, () => { this.detail.state = 1; }, (state == 2), "是否确定提交？");
    },
    auditVacation: function (pass, memo) {
      auditAction(this, AuditVacation, this.id, pass, memo);
    },
    cancelVacation: function () {
      cancelAction(this, CancelVacation, this.id);
    },
    dataRequest: function () {
      loadDetailAction(this, GetVacation, { id: this.id }, (data) => {
        this.detail = data.model;
        this.flows = data.flows;
        this.buttons = data.buttons;
        this.disabled = !this.buttons || this.buttons.indexOf("保存草稿") < 0;
      });
    }
  },
  mounted() {
    loadDataOptionAction(this, "请假类型", (data) => { this.typeOption = data; });

    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.dataRequest();
    }
  },
};
</script>
